<template lang="pug">
  v-card(width="800" tile)
    .pa-7.pb-0
      h6 Standing
      v-select(
          v-model="type"
          item-text="name"
          item-value="value"
          :items="typeSelect"
          placeholder="Please select your standing"
          rounded
          filled
          dense
          :rules="[(v) => !!v || 'Standing is required. Please select one.']"
          @change="typeChange()"
      ).mt-3
      div(v-if="type=='student' || type=='faculty'")
        h6 College Unit
        v-autocomplete(
          :items="colleges"
          rounded
          filled
          dense
          v-model.trim="college"
          placeholder="Type and select college"
          :rules="[(v) => !!v || 'College is required. Please select one.']"
          @change="institutionChange()"
          ).mt-3
        div(v-if="college=='College of Science'")
          h6 Institute
          v-autocomplete(
            :items="institutes"
            rounded
            filled
            dense
            v-model.trim="institute"
            item-text="name"
            item-value="name"
            placeholder="Type and select institute"
            required
            :rules="[(v) => !!v || 'Institute is required. Please select one.']"
            @change="institutionChange()"
            ).mt-3
        h6 CS Courses 
          span(v-if="type=='student'") Taking 
          span(v-if="type=='faculty'") Teaching
          span  (e.g. Math 21, MS 101, Env. Sci. 1)
        v-list
          v-list-item(
          v-for="item, i in courses"
          :key="i"
          )
            v-list-item-icon
              v-icon mdi-arrow-right-bottom-bold
            v-list-item-action.text-muted.mr-1 Course {{i+1}}:
            v-list-item-content
              v-list-item-title
                v-chip {{item}}
            v-list-item-action
              v-btn(color="error" x-small @click="courses.splice(i, 1); course=''; lastCourse=''; entries=[]" :disabled="isLoading") remove
        v-text-field(
          v-model.trim="course"
          placeholder="Search Course"
          rounded
          filled
          dense
          clearable
          @keyup="search()"
          prepend-inner-icon="mdi-magnify"
          )
        div.caption.muted.mt-n5.text-center Please type the exact course code. This is case and space sensitive. Click among the course chips below to add the course.
        div.text-center
          div(v-if="isLoading")
            v-progress-circular(
              indeterminate
              color="primary"
              ).mt-3
          div(v-else-if="entries.length==0 && course.length>=3") No course found. Please enter the correct course code.
          span(v-for="entry, i in entries" :key="i" v-else)
            v-chip(
              class="ma-2"
              color="primary"
              outlined
              @click="addCourse(i, entry.course)"
            )
              v-icon(left) mdi-plus-circle
              span {{entry.course}}
    v-divider
    v-card-actions.px-7.pb-5
        v-spacer
        v-btn(small color="primary" @click="updateProfile()" :loading="loading").ml-5 Update
</template>

<script>
import colleges from "@/colleges.js";
import firebase from "firebase/app";
import "firebase/functions";
let updateProfile = firebase.functions().httpsCallable("updateProfile");
import { db } from "../main";
export default {
  name: "EditAccount",
  data() {
    return {
      typeSelect: [
        { name: "Student", value: "student" },
        { name: "Faculty", value: "faculty" },
        { name: "Staff", value: "staff" },
      ],
      colleges: colleges.colleges,
      institutes: [
        "Institute of Biology",
        "Institute of Chemistry",
        "Institute of Environmental Science and Meteorology",
        "Institute of Mathematics",
        "Marine Science Institute",
        "Materials Science and Engineering Program",
        "National Institute of Geological Sciences",
        "National Institute of Molecular Biology and Biotechnology",
        "National Institute of Physics",
      ],
      course: "",
      courses: [],
      entries: [],
      isLoading: false,
      lastCourse: "",
      institution: this.$store.state.userInfo.institution,
      type: this.$store.state.userInfo.type,
      college: "",
      institute: "",
      loading: false,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userToken.user_id;
    },
  },
  created() {
    if (this.institutes.includes(this.$store.state.userInfo.institution)) {
      this.college = "College of Science";
      this.institute = this.$store.state.userInfo.institution;
    } else {
      this.college = this.$store.state.userInfo.institution;
    }
    if (this.$store.state.userInfo.courses) {
      this.courses = this.$store.state.userInfo.courses;
    } else this.courses = [];
  },
  watch: {},
  methods: {
    typeChange() {
      if (this.type == "staff") {
        this.courses = ["Not Applicable"];
        this.institution = "Not Applicable";
      } else {
        this.courses = [];
        this.institution = "";
        this.college = "";
        this.institute = "";
      }
    },
    institutionChange() {
      if (this.college == "College of Science") {
        this.institution = this.institute;
      } else {
        this.institution = this.college;
      }
    },
    async search() {
      if (this.course.length < 3) return;
      if (this.lastCourse == this.course) return;
      this.lastCourse = this.course;
      this.isLoading = true;
      const dataBase = await db
        .collection("courseReferences2")
        .orderBy("course")
        .startAt(this.course)
        .endAt(this.course + "\uf8ff")
        .limit(5);
      const dbResults = await dataBase.get();
      var entries = [];
      dbResults.forEach((doc) => {
        if (!this.courses.includes(doc.data().course)) {
          const data = {
            course: doc.data().course,
          };
          entries.push(data);
        }
      });
      if (entries.length == 0) {
        this.lastCourse = "";
      }
      this.entries = entries;
      this.isLoading = false;
    },
    addCourse(i, course) {
      this.courses.push(course);
      this.entries.splice(i, 1);
      this.$store.dispatch("setSnackbar", {
        text: course + " added to your courses!",
        icon: "mdi-check",
        iconColor: "success",
      });
    },
    async updateProfile() {
      if (
        this.type == "" ||
        this.institution == "" ||
        (this.courses && this.courses.length == 0)
      ) {
        this.$store.dispatch("setSnackbar", {
          text: "  Please fill the required fields correctly!",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
        return;
      }
      this.loading = true;
      await updateProfile({
        id: this.userId,
        newProfile: {
          type: this.type,
          institution: this.institution,
          courses: this.courses,
        },
      })
        .then(() => {
          this.$store.dispatch("getUserInfo");
          this.$store.commit("setUpdate", false);
        })
        .finally(() => {
          this.$store.dispatch("setSnackbar", {
            text: " Profile has been updated",
            icon: "mdi-check",
            iconColor: "success",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
