<template lang="pug">
div
  v-dialog(v-model="needUpdate" :persistent="!user.institution" width="800")
    v-card(width="800" tile)
      v-card-title.headline.grey.lighten-2 
        span Edit Profile
        v-spacer
        span
          v-btn(icon @click="needUpdate=false" v-if="user.institution")
            v-icon mdi-close-thick
    EditAccount
  v-dialog(v-model="updateName" persistent width="800")
    v-card(width="800" tile)
      v-card-title.headline.grey.lighten-2 
        span Edit Name
        v-spacer
        span
          v-btn(icon @click="updateName=false")
            v-icon mdi-close-thick
      .pa-7.pb-2
        h6 Full Name
        v-text-field(
          v-model="userName"
          placeholder="Please type your full name."
          rounded
          filled
          dense
          :disabled="loading"
          :rules="[(v) => !!v || 'Full name is required.']"
          ).mt-3
      v-divider
      v-card-actions.px-7.pb-5
        v-spacer
        v-btn(text @click="updateName=false" small) Cancel
        v-btn(small color="primary" @click="updateNameFunction()" :loading="loading").ml-5 Update
  div.d-flex.text-justify(v-if="$vuetify.breakpoint.xs")
    h2.mr-1.mb-5.font-weight-black.head-text USER
    h2.font-weight-light.ml-2 PROFILE
  div.d-flex.justify-content-center(v-else)
    span.mr-1.font-weight-black.display-1.head-text USER
    span.font-weight-light.display-1.ml-2 PROFILE
  
  //- Personal Information
  div(v-if="user==''").mt-15.text-center
      v-progress-circular.mt-15(
        :size="70"
        :width="7"
        color="primary"
        indeterminate)
  div(v-else)
    h4.font-weight-light Personal Information
    v-divider
    v-card.pa-7( v-if="$vuetify.breakpoint.smAndDown")
      v-row
        v-col.px-8(md="3", cols="12")
          h6.body-1.font-weight-medium Full Name:
        v-col.px-8.mt-n8(md="9", cols="12")
          h6.font-weight-light {{user.name}}
            span.ml-3
              v-btn(color="info" x-small @click="userName=user.name; updateName=true" v-if="user.type=='faculty'").mb-1 Edit
      v-row
        v-col.px-8(md="3", cols="12") 
          h6.body-1.font-weight-medium UP Mail:
        v-col.px-8.mt-n8(md="9", cols="12")
          h6.font-weight-light {{user.email}}
      v-row
        v-col.px-8(md="3", cols="12")
          h6.body-1.font-weight-medium Type:
        v-col.px-8.mt-n8(md="9", cols="12")
          h6.text-capitalize.font-weight-light(v-if="user.admin || user.type=='administrator'") Administrator ({{ user.type }})
          h6.text-capitalize.font-weight-light(v-else) {{ user.type }}
      v-row
        v-col.px-8(md="3", cols="12")
          h6.body-1.font-weight-medium 
            span(v-if="user.type=='student'") Student Number:
            span(v-else) Employee Number:
        v-col.px-8.mt-n8(md="8", cols="12")
          h6.font-weight-light {{ user.number }}
    v-card.pa-7(v-else)
      v-row
        v-col.px-8.mr-n16(md="4", cols="12")
          h6.body-1.font-weight-medium Full Name:
        v-col(md="8", cols="12")
          h6.font-weight-light {{user.name}}
            span.ml-3
              v-btn(color="info" x-small @click="userName=user.name; updateName=true" v-if="user.type=='faculty'").mb-1 Edit
      v-row
        v-col.px-8.mr-n16(md="4", cols="12") 
          h6.body-1.font-weight-medium UP Mail:
        v-col(md="8", cols="12")
          h6.font-weight-light {{user.email}}
      v-row
        v-col.px-8.mr-n16(md="4", cols="12")
          h6.body-1.font-weight-medium Type:
        v-col(md="8", cols="12")
          h6.text-capitalize(v-if="user.admin || user.type=='administrator'").font-weight-light Administrator ({{ user.type }})
          h6.text-capitalize(v-else).font-weight-light {{ user.type }}
      v-row
        v-col.px-8.mr-n16(md="4", cols="12")
          h6.body-1.font-weight-medium
            span(v-if="user.type=='student'") Student Number:
            span(v-else) Employee Number:
        v-col(md="8", cols="12")
          h6.font-weight-light {{ user.number }}
    br
    div(v-if="user.type!='staff' && user.institution")
      h4.font-weight-light Academic Information
      v-divider
      v-card.pa-7
        v-row(v-if="user.institution && user.institution!='College of Science'")
          v-col.px-8.mr-n16(md="4", cols="12") 
            h6.body-1.font-weight-medium College Unit/Institution:
          v-col(md="8", cols="12")
            h6.font-weight-light.mt-n7.ml-5.mt-md-1 {{user.institution}}
        v-row(v-if="user.courses")
          v-col.px-8.mr-n16(md="4", cols="12") 
            h6.body-1.font-weight-medium Courses:
          v-col(md="8", cols="12")
            span(v-for="course in user.courses").ml-5
              v-chip.font-weight-light.mr-2.mt-n7.mt-md-0 {{course}}
      br
    h4.font-weight-light User Agreement
    v-divider 
    v-card.pa-5
      h6.font-weight-light I, <strong>{{ user.name }}</strong>, acknowledge that I have read the Acceptable Use Policy of CS Libraries eBook Hub.
      div.form-inline
        v-icon(color="primary").mb-2 mdi-checkbox-marked
        h6(@click="dialog = !dialog").ml-1.font-weight-bold Acceptable Use Policy
      v-dialog(v-model="dialog", app, max-width="600", persistent scrollable)
        v-card(tile)
          v-card-title.headline.grey.lighten-2 Acceptable Use Policy
          v-card-text(style="height: 600px;")
            div.text-caption.font-weight-regular.ma-4 Note: After reading the Acceptable Use Policy, please click the "Agree" button found at the bottom of this dialog box. 
            h5.ma-4 Overview
              h6.font-weight-regular.px-2.pb-3.pt-2 This policy is set to establish a sensible conduct in utilizing the CSLib eBook Hub. It aims to nurture an existing community of ethical, accountable, and responsible users. Further, this will help avoid overstepping the boundaries of the existing rules of Intellectual Property and Fair Use.
            h5.ma-4 Scope
              h6.font-weight-regular.px-2.pb-3.pt-2 This policy applies to all registered Users.
            h5.ma-4 Users
              h6.font-weight-regular.px-2.pb-3.pt-2 1. Students
                v-card-subtitle.font-weight-regular.px-2.pb-2 1.1 Undergraduate
                v-card-subtitle.font-weight-regular.px-2.pb-2 1.1 Graduate
                v-card-subtitle.font-weight-regular.px-2.pb-2 1.1 Post Graduate
              h6.font-weight-regular.px-2.pb-3.pt-2 2. Faculty
                v-card-subtitle.font-weight-regular.px-2.pb-2 2.1 Permanent Status
                v-card-subtitle.font-weight-regular.px-2.pb-2 2.1 Temporary Status
            h5.ma-4 Access
              h6.font-weight-bold.px-2.pb-3.pt-2 Access is granted through registration. Requirement/s are as follows:
                h6.font-weight-regular.px-2.pb-3.pt-2 1. Students
                  v-card-subtitle.px-2.py-2 • Must be currently enrolled.
                  v-card-subtitle.px-2.py-2 • UP Mail Account
                h6.font-weight-regular.px-2.pb-3.pt-2 2. Faculty
                  v-card-subtitle.font-weight-bold.px-2.py-2 2.1 Permanent Faculty
                    v-card-subtitle.px-2.py-2 • UP Mail Account
                  v-card-subtitle.font-weight-bold.px-2.py-2 2.2 Temporary Faculty
                    v-card-subtitle.px-2.py-2 • Most recent appointment
                    v-card-subtitle.px-2.pt-2.mb-n10 • UP Mail Account
            h5.ma-4 Acceptable Use
              h6.font-weight-regular.px-2.pb-3.pt-2 1. Use of any device capable of accessing the database anytime and anywhere.
            h5.ma-4.text-justify Unacceptable Use
              h6.font-weight-regular.px-2.pb-3.pt-2 1. Sharing of account - giving or lending your username and password to others.
              h6.font-weight-regular.px-2.pb-2.pt-2 2. Download by any means (third party programs, etc.)
              h6.font-weight-regular.px-2.pb-2.pt-2 3. Cheating.
              h6.font-weight-regular.px-2.pb-2.pt-2 4. Hack attempts.
              h6.font-weight-regular.px-2.pb-2.pt-2 5. Not logging out after use.
              h6.font-weight-regular.px-2.pb-2.pt-2 6. Commercial use.
              h6.font-weight-regular.px-2.pb-2.pt-2 7. Other uses aside from academic purpose.
            h5.ma-4 Sanctions
              h6.font-weight-regular.px-2.pb-3.pt-2 Any proven violation of this policy will result in <strong>indefinite suspension of account</strong>.
            h6.font-weight-light Sponsored by the <em> College of Science, University of the Philippines Diliman </em>    
          v-divider
          v-card-actions
            v-btn(color="grey" dark @click="dialog=false").ml-auto.mr-4.mb-4 Close
</template>
<script>
import EditAccount from "../components/EditAccount.vue";
import firebase from "firebase/app";
import "firebase/functions";
let updateProfile = firebase.functions().httpsCallable("updateProfile");

export default {
  components: { EditAccount },
  data() {
    return {
      dialog: false,
      updateName: false,
      userName: "",
      loading: false,
      userId: this.$store.state.userToken.user_id,
      users: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.userInfo;
    },
    needUpdate: {
      get() {
        return this.$store.state.needUpdate;
      },
      set(payload) {
        this.$store.commit("setUpdate", payload);
      },
    },
  },
  methods: {
    async updateNameFunction() {
      console.log(this.userId);
      this.loading = true;
      await updateProfile({
        id: this.userId,
        newProfile: {
          name: this.userName,
        },
      })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            text: " Profile has been updated",
            icon: "mdi-check",
            iconColor: "success",
          });
          this.user.name = this.userName;
        })
        .catch((err) => {
          this.$store.dispatch("setSnackbar", {
            text: `    ${err.message}`,
            icon: "mdi-block-helper",
            iconColor: "error",
          });
        });
      this.loading = false;
      this.updateName = false;
    },
  },
};
</script>
<style scoped>
.head-text {
  color: #010073;
}
</style>
